import React from "react";

function MuscleSpasms() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="103"
            height="103"
            fill="none"
            viewBox="0 0 103 103"
        >
            <circle cx="51.5" cy="51.5" r="51.5" fill="#1E4E35"></circle>
            <mask
                id="mask0_4_89"
                style={{ maskType: "alpha" }}
                width="50"
                height="50"
                x="26"
                y="27"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M26 27H76V77H26z"></path>
            </mask>
            <g fill="#fff" mask="url(#mask0_4_89)">
                <path d="M60.434 34.205c-1.398-.138-2.982-.296-3.31-.461-.787-.398-1.047-2.201-1.049-3.011A.73.73 0 0055.35 30h-8.7a.73.73 0 00-.726.733c0 .81-.257 2.61-1.049 3.01-.328.166-1.912.323-3.31.462-4.226.42-10.013.993-11.488 3.484-2.215 3.733-.37 18.24-.106 20.21l-.723 15.332c-.009.2.063.397.2.541a.723.723 0 00.525.228h5.075c.363 0 .67-.27.72-.635.187-1.4 1.616-12.021 2.054-16.167.356 1.749.68 3.196.89 3.834.563 1.711.687 9.412.687 12.234a.73.73 0 00.725.734h21.752a.73.73 0 00.725-.734c0-2.822.123-10.522.688-12.234.21-.639.534-2.085.89-3.834.438 4.146 1.866 14.768 2.055 16.167a.727.727 0 00.718.635h5.075a.723.723 0 00.525-.227.74.74 0 00.2-.541l-.723-15.333c.264-1.969 2.109-16.478-.105-20.209-1.476-2.492-7.264-3.066-11.489-3.485zm10.832 38.328h-3.683c-.652-4.866-2.082-15.755-2.082-16.866 0-.758-.178-2.678-.29-3.83.532-2.88.965-5.35 1.004-5.576l-1.429-.255c-.022.124-2.174 12.438-2.874 14.562-.65 1.974-.746 9.45-.76 11.965H40.846c-.014-2.515-.11-9.992-.76-11.965-.7-2.124-2.852-14.438-2.874-14.562l-1.428.255c.039.226.47 2.696 1.004 5.577-.112 1.15-.29 3.07-.29 3.828 0 1.111-1.43 12-2.083 16.867h-3.682l.689-14.632a.743.743 0 00-.006-.134c-.595-4.417-1.774-16.498-.096-19.324 1.1-1.858 6.913-2.434 10.386-2.778 2.135-.212 3.274-.335 3.817-.609 1.332-.674 1.706-2.531 1.81-3.59h7.331c.105 1.059.479 2.916 1.812 3.59.542.274 1.681.398 3.816.609 3.474.344 9.286.92 10.388 2.78 1.676 2.825.498 14.905-.097 19.323a.742.742 0 00-.006.134l.689 14.631z"></path>
                <path d="M47.374 49.496v-4.83h-1.45v4.83l-1.663-1.68-1.025 1.036 2.9 2.933a.719.719 0 001.026 0l2.9-2.933-1.025-1.037-1.663 1.681zM56.075 49.496v-4.83h-1.45v4.83l-1.663-1.68-1.025 1.036 2.9 2.933a.719.719 0 001.025 0l2.9-2.933-1.024-1.037-1.663 1.681zM45.924 41.733h1.45V43.2h-1.45v-1.467zM45.924 38.8h1.45v1.467h-1.45V38.8zM54.625 41.733h1.45V43.2h-1.45v-1.467zM54.625 38.8h1.45v1.467h-1.45V38.8zM54.837 55.148l-2.9 2.933 1.025 1.037 1.662-1.681v4.83h1.45v-4.83l1.663 1.68 1.025-1.036-2.9-2.933a.718.718 0 00-1.025 0zM46.136 55.148l-2.9 2.933 1.025 1.037 1.662-1.681v4.83h1.45v-4.83l1.663 1.68 1.025-1.036-2.9-2.933a.718.718 0 00-1.025 0zM54.625 63.733h1.45V65.2h-1.45v-1.467zM54.625 66.666h1.45v1.467h-1.45v-1.467zM45.924 63.733h1.45V65.2h-1.45v-1.467zM45.924 66.666h1.45v1.467h-1.45v-1.467z"></path>
            </g>
        </svg>
    );
}

export default MuscleSpasms;
