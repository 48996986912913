import React from "react";

function Skin() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="103"
            height="103"
            fill="none"
            viewBox="0 0 103 103"
        >
            <circle cx="51.5" cy="51.5" r="51.5" fill="#1E4E35"></circle>
            <mask
                id="mask0_5_90"
                style={{ maskType: "alpha" }}
                width="50"
                height="50"
                x="26"
                y="27"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M26 27H76V77H26z"></path>
            </mask>
            <g fill="#fff" mask="url(#mask0_5_90)">
                <path d="M51.333 30C39.05 30 29 40.05 29 52.333c0 12.284 10.05 22.333 22.333 22.333 11.074 0 22.333-8.933 22.333-22.333C73.666 40.05 63.616 30 51.333 30zm0 1.861c11.26 0 20.472 9.213 20.472 20.472v.745h-17.96a.893.893 0 00-.837.651l-1.954 6.421-5.304-6.607c-.186-.186-.465-.372-.744-.372H30.86v-.838c0-11.26 9.213-20.472 20.472-20.472zm0 40.944c-10.422 0-18.983-7.723-20.286-17.773H44.54l6.142 7.63c.186.187.465.373.744.373h.186c.373-.093.559-.28.745-.652l2.326-7.351h17.122c-1.395 9.957-10.05 17.774-20.472 17.774z"></path>
                <path d="M50.03 50.565c1.955 0 3.63-1.582 3.63-3.629 0-2.047-1.582-3.63-3.63-3.63-1.954 0-3.629 1.583-3.629 3.63 0 2.047 1.675 3.63 3.63 3.63zm0-5.397c1.024 0 1.769.838 1.769 1.768s-.838 1.769-1.768 1.769c-.931 0-1.769-.838-1.769-1.769 0-.93.838-1.768 1.769-1.768zM58.684 62.755c-1.581 0-2.791 1.21-2.791 2.792 0 1.582 1.21 2.792 2.791 2.792 1.582 0 2.792-1.21 2.792-2.792 0-1.582-1.303-2.791-2.791-2.791zm0 3.722c-.558 0-.93-.372-.93-.93s.372-.93.93-.93c.559 0 .931.372.931.93 0 .559-.465.93-.93.93zM65.291 55.311c-1.954 0-3.629 1.582-3.629 3.63 0 2.047 1.582 3.628 3.63 3.628 1.954 0 3.629-1.581 3.629-3.629 0-2.047-1.675-3.629-3.63-3.629zm0 5.397a1.767 1.767 0 01-1.768-1.768c0-1.023.838-1.768 1.768-1.768s1.769.838 1.769 1.768-.745 1.768-1.769 1.768zM40.074 58.01c-1.955 0-3.63 1.582-3.63 3.629 0 2.047 1.582 3.629 3.63 3.629 2.047 0 3.629-1.582 3.629-3.63 0-2.046-1.675-3.628-3.63-3.628zm0 5.304a1.767 1.767 0 01-1.769-1.768c0-.93.838-1.768 1.769-1.768.93 0 1.768.837 1.768 1.768 0 .93-.745 1.768-1.768 1.768zM48.17 63.314c-1.955 0-3.63 1.582-3.63 3.629 0 2.047 1.582 3.63 3.63 3.63 2.047 0 3.628-1.583 3.628-3.63 0-2.047-1.675-3.63-3.629-3.63zm0 5.397a1.767 1.767 0 01-1.769-1.768c0-1.024.837-1.768 1.768-1.768.93 0 1.769.837 1.769 1.768 0 .93-.838 1.768-1.769 1.768zM40.632 47.68l-1.396-.279c-.465-.093-.93.186-1.117.652-.093.465.186 1.024.652 1.117l3.443.744c.186.093.465 0 .651-.093s.372-.372.372-.558l.745-3.443c.093-.466-.186-1.024-.651-1.117-.466-.093-.93.186-1.117.651l-.28 1.21-3.535-6.235a.941.941 0 00-1.21-.372.941.941 0 00-.372 1.21l3.815 6.514zM56.451 49.263c.093.279.186.465.373.558.186.093.465.186.65.093l3.444-.745c.465-.093.837-.558.651-1.116-.186-.558-.558-.838-1.116-.652l-1.303.28 3.629-6.514c.279-.465.093-1.023-.373-1.21-.465-.279-1.023-.093-1.21.373l-3.535 6.234-.28-1.21c-.093-.465-.558-.837-1.116-.65-.465.093-.837.558-.651 1.116l.837 3.443z"></path>
            </g>
        </svg>
    );
}

export default Skin;
