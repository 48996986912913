import React from "react";

function Headache() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="103"
            height="103"
            fill="none"
            viewBox="0 0 103 103"
        >
            <circle cx="51.5" cy="51.5" r="51.5" fill="#1E4E35"></circle>
            <mask
                id="mask0_5_91"
                style={{ maskType: "alpha" }}
                width="50"
                height="50"
                x="26"
                y="27"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M26 27H76V77H26z"></path>
            </mask>
            <g fill="#fff" mask="url(#mask0_5_91)">
                <path d="M51.575 36.604a.92.92 0 01-.845-1.285l.842-1.95h-.69a.921.921 0 01-.837-1.304l1.482-3.228a.92.92 0 111.673.767l-.884 1.925h.654a.92.92 0 01.845 1.284l-1.395 3.235a.92.92 0 01-.845.556zM59.955 39.468a.92.92 0 01-.78-1.406l1.12-1.803-.682-.103a.92.92 0 01-.633-1.413l1.945-2.974a.92.92 0 011.54 1.008l-1.16 1.772.647.097a.92.92 0 01.645 1.396l-1.86 2.992a.92.92 0 01-.782.434zM66.126 44.837a.919.919 0 01-.545-1.662l1.714-1.256-.595-.35a.92.92 0 01-.06-1.547l2.914-2.033a.92.92 0 011.052 1.51l-1.737 1.211.564.332a.922.922 0 01.078 1.535l-2.842 2.082a.915.915 0 01-.543.178zM43.595 38.403a.92.92 0 01-.782-.434l-1.86-2.993a.92.92 0 01.645-1.395l.647-.097-1.16-1.773a.92.92 0 111.54-1.007l1.946 2.973a.92.92 0 01-.634 1.413l-.682.103 1.121 1.804a.92.92 0 01-.78 1.406zM36.643 43.324a.914.914 0 01-.543-.178l-2.842-2.082a.92.92 0 01.077-1.535l.564-.332-1.737-1.212a.92.92 0 111.053-1.509l2.914 2.033a.92.92 0 01-.06 1.547l-.596.35 1.714 1.255a.92.92 0 01-.544 1.663zM58.273 75.404a.92.92 0 01-.92-.92v-2.961c0-1.063.82-1.895 1.866-1.895h3.615c.775 0 1.406-.71 1.406-1.585v-4.618c0-1.69.988-2.87 2.402-2.87.405 0 .72-.139.843-.372.15-.283.035-.751-.307-1.253a.647.647 0 01-.028-.043l-3.445-5.707a.918.918 0 01-.132-.476c0-7.016-5.709-12.724-12.724-12.724-3.392 0-6.783 1.372-9.305 3.765-2.58 2.446-4 5.643-4 9.001 0 5.172 1.787 8.05 3.364 10.588 1.306 2.105 2.54 4.091 2.54 6.913v4.237a.92.92 0 01-1.84 0v-4.237c0-2.296-1.049-3.986-2.263-5.942-1.622-2.612-3.64-5.862-3.64-11.558 0-7.917 6.935-14.606 15.144-14.606 7.944 0 14.423 6.393 14.562 14.304l3.302 5.471c.732 1.085.878 2.223.4 3.127-.448.847-1.371 1.353-2.47 1.353-.535 0-.562.857-.562 1.03v4.618c0 1.889-1.456 3.425-3.246 3.425H59.22c-.013.003-.027.018-.027.055v2.96a.92.92 0 01-.92.921z"></path>
                <path d="M50.4 55.724c-3.22 0-5.84-2.62-5.84-5.84 0-3.22 2.62-5.84 5.84-5.84 3.22 0 5.84 2.62 5.84 5.84 0 3.22-2.62 5.84-5.84 5.84zm0-9.84c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4z"></path>
                <path d="M51.384 49.884a.984.984 0 11-1.968 0 .984.984 0 011.968 0z"></path>
            </g>
        </svg>
    );
}

export default Headache;
