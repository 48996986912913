import React from "react";

function Deafness() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="103"
            height="103"
            fill="none"
            viewBox="0 0 103 103"
        >
            <circle cx="51.5" cy="51.5" r="51.5" fill="#1E4E35"></circle>
            <mask
                id="mask0_4_88"
                style={{ maskType: "alpha" }}
                width="50"
                height="50"
                x="26"
                y="27"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M26 27H76V77H26z"></path>
            </mask>
            <g fill="#fff" mask="url(#mask0_4_88)">
                <path d="M38.91 41.671a17.328 17.328 0 00-.45 4.019c0 4.148 1.14 6.052 4.487 8.865a202.2 202.2 0 011.59 1.335c3.475 2.995 5.384 5.869 6.358 10.736 1.531 7.658 12.033 7.231 12.47-.499l-2.5-2.5c-.442 4.9-7.454 4.735-7.454-.494 0-3.389-.964-5.221-3.367-7.594-.167-.165-.809-.788-.958-.936-2.055-2.025-3.014-3.577-3.137-5.891l-7.04-7.04zm26.639 26.64c-2.21 8.692-15.145 8.567-17.098-1.196-.854-4.266-2.456-6.679-5.542-9.337-.36-.312-1.785-1.5-1.566-1.316-3.86-3.243-5.376-5.776-5.376-10.773 0-2.252.326-4.27.908-6.052l-10.51-10.51a1.246 1.246 0 011.762-1.762L75.473 74.71a1.246 1.246 0 01-1.762 1.762l-8.162-8.162zM54.65 57.414c.852 1.61 1.252 3.393 1.252 5.719 0 2.076 2.492 2.076 2.492 0 0-.535.336-.99.809-1.168l-4.553-4.551zM41.108 36.84l-1.809-1.81C47.094 25.025 65.87 28.578 65.87 45.69a1.246 1.246 0 01-2.491 0c0-14.577-15.855-17.526-22.271-8.849zm8.977 8.977l-1.89-1.89c2.941-1.76 7.708-.342 7.708 4.253a1.246 1.246 0 01-2.492 0c0-2.038-1.876-2.826-3.326-2.363z"></path>
                <path d="M53.757 53.014l-1.533-1.533c.279.702.807 1.273 1.533 1.533zm-1.153-4.677c.342-.496.835-.939 1.496-1.27 1.61-.805 1.803-1.19 1.803-2.623 0-3.215-2.041-4.984-4.983-4.984-2.198 0-4.037 1.012-4.71 2.484l-1.835-1.836c1.292-1.944 3.787-3.14 6.544-3.14 4.246 0 7.476 2.8 7.476 7.476 0 2.305-.638 3.58-3.18 4.852-.47.235-.673.591-.733.92l-1.878-1.879zm-7.491-3.967a1.246 1.246 0 01-1.669-1.172c0-.161.007-.32.02-.477l1.649 1.65z"></path>
            </g>
        </svg>
    );
}

export default Deafness;
