import React from "react";

function ProblemsHearingNoises() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="103"
            height="103"
            fill="none"
            viewBox="0 0 103 103"
        >
            <circle cx="51.5" cy="51.5" r="51.5" fill="#1E4E35"></circle>
            <mask
                id="mask0_3_87"
                style={{ maskType: "alpha" }}
                width="50"
                height="50"
                x="26"
                y="27"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M26 27H76V77H26z"></path>
            </mask>
            <g mask="url(#mask0_3_87)">
                <path
                    fill="#fff"
                    d="M59.716 47.468l1.465-3.194a1.044 1.044 0 011.1-.595l3.33.498 1.136-2.48c.193-.423.649-.665 1.101-.597l3.324.5 1.134-2.478a1.042 1.042 0 011.894.866l-1.46 3.191a1.043 1.043 0 01-1.101.597l-3.325-.5-1.136 2.48a1.043 1.043 0 01-1.1.596l-3.33-.498-1.138 2.481a1.041 1.041 0 01-1.894-.867zm13.02 13.08a1.04 1.04 0 00-1.101-.596l-3.33.498-1.136-2.48a1.048 1.048 0 00-1.101-.596l-3.324.5-1.134-2.479a1.042 1.042 0 00-1.894.867l1.46 3.19c.193.423.648.664 1.101.597l3.325-.499 1.136 2.48a1.04 1.04 0 001.1.595l3.33-.498 1.138 2.482a1.04 1.04 0 001.381.512 1.04 1.04 0 00.513-1.38l-1.465-3.193zM55.327 36.431V67.3c0 .839-.423 1.607-1.133 2.054a2.417 2.417 0 01-2.34.14l-14.764-7.03h-7.455c-1.34 0-2.43-1.09-2.43-2.43V43.697c0-1.34 1.09-2.43 2.43-2.43h7.455l14.763-7.03a2.419 2.419 0 012.343.14 2.42 2.42 0 011.131 2.055zm-19.043 6.92h-6.649a.348.348 0 00-.347.346v16.336c0 .191.155.347.347.347h6.649V43.35zm16.96-6.92a.337.337 0 00-.162-.293.337.337 0 00-.334-.02l-14.382 6.848v17.797l14.382 6.849a.336.336 0 00.334-.02.338.338 0 00.162-.295V36.432z"
                ></path>
            </g>
        </svg>
    );
}

export default ProblemsHearingNoises;
