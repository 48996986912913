import React, { ReactElement } from "react";

import colors from "@styles/variables/tepezzaVariables.module.scss";
import HearingLoss from "./icons/hearingLoss";
import Deafness from "./icons/deafness";
import ProblemsHearingNoises from "./icons/problemsHearingNoises";
import Skin from "./icons/skin";
import MuscleSpasms from "./icons/muscleSpasms";
import Headache from "./icons/headache";

import FeatureList from "@components/shared/featureList";

const sideEffectList = [
    {
        title: "Hearing loss",
        icon: <HearingLoss />,
    },
    {
        title: "Problems hearing noises",
        icon: <ProblemsHearingNoises />,
    },
    {
        title: "Deafness",
        icon: <Deafness />,
    },
    {
        title: "Muscle spasms",
        icon: <MuscleSpasms />,
    },
    {
        title: "Dry skin",
        icon: <Skin />,
    },
    {
        title: "Headache",
        icon: <Headache />,
    },
];

export default function SideEffects(): ReactElement {
    return (
        <FeatureList
            featureList={sideEffectList}
            mainTitle="Tepezza Injuries & Side Effects"
            mainDescription={
                <>
                    <span className="block capitalize">
                        The most common side effects of Tepezza are nausea,
                        diarrhea, fatigue, muscle spasm, dry skin, changes in
                        taste and menstrual changes. The most serious Tepezza
                        side effects are infusion reactions, worsening of
                        inflammatory bowel disease, and hyperglycemia (high
                        blood sugar).
                    </span>
                    <span className="block mt-4 color-[#7d7d7d] font-bold text-lg">
                        Other adverse effects that have been associated with
                        Tepezza include:
                    </span>
                </>
            }
            classes={{
                mainSectionClasses:
                    "mx-auto lg:max-w-7xl flex justify-center items-start flex-col px-8 ",
                mainTitleClasses:
                    "text-left font-bold leading-10 text-2xl mb-8 mt-20",
                innerSectionClasses:
                    "flex flex-wrap justify-center mt-12 gap-16 mb-20",
                oneBoxClasses:
                    "flex flex-col items-center justify-center w-52 md:w-64 mx-10 text-center",
                titleClasses: "mt-5 capitalize text-sm md:text-lg",
                mainDescriptionClasses:
                    "text-left text-sm md:text-lg leading-8",
                iconClasses: " ",
            }}
            colors={{
                mainDescriptionColor: colors.tepezzaGrey,
                mainTitleColor: colors.mainColor,

                bgColor: colors.secondaryColor,
            }}
        />
    );
}
