import React from "react";

function HearingLoss() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="111"
            height="111"
            fill="none"
            viewBox="0 0 111 111"
        >
            <g filter="url(#filter0_d_3_86)">
                <circle cx="55.5" cy="51.5" r="51.5" fill="#1E4E35"></circle>
                <mask
                    id="mask0_3_86"
                    style={{ maskType: "alpha" }}
                    width="50"
                    height="50"
                    x="30"
                    y="27"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#D9D9D9" d="M30 27H80V77H30z"></path>
                </mask>
                <g fill="#fff" mask="url(#mask0_3_86)">
                    <path d="M45.893 56.82a1.035 1.035 0 001.5 0 1.035 1.035 0 000-1.5c-1.393-1.393-3.697-1.393-5.143 0-1.393 1.393-1.393 3.697 0 5.143 1.178 1.179 1.5 1.553 1.5 2.84a3.646 3.646 0 003.642 3.642 3.612 3.612 0 003.643-3.643c0-.59-.483-1.072-1.072-1.072-.59 0-1.072.483-1.072 1.072 0 .804-.643 1.5-1.5 1.5-.856 0-1.5-.643-1.5-1.5 0-2.089-.803-3-2.142-4.339a1.497 1.497 0 01-.429-1.071c0-.43.16-.75.429-1.072.59-.59 1.555-.59 2.144 0z"></path>
                    <path d="M71.714 37.75a8.253 8.253 0 00-8.25-8.25c-3.16 0-5.893 1.768-7.286 4.393a14.806 14.806 0 00-3.053-.321c-8.09 0-14.625 6.59-14.625 14.625v3.053c0 .59.482 1.072 1.072 1.072.589 0 1.071-.482 1.071-1.072v-3.053c0-6.91 5.625-12.483 12.483-12.483.803 0 1.553.054 2.303.215-.107.589-.214 1.178-.214 1.768 0 .214 0 .375.053.589a9.23 9.23 0 00-2.089-.214c-4.928 0-8.893 4.018-8.893 8.892 0 2.946 2.143 5.036 3.858 6.697.16.16.375.32.536.535.214.215.482.322.75.322.267 0 .535-.108.75-.322a1.035 1.035 0 000-1.5l-.536-.535c-1.554-1.553-3.215-3.107-3.215-5.197 0-3.75 3.054-6.75 6.75-6.75.91 0 1.822.161 2.625.43.857 2.25 2.732 4.07 5.036 4.874.321.91.536 1.875.536 2.893 0 3.535-1.447 4.874-3.482 6.803-.43.429-.483 1.072-.054 1.5.214.214.482.32.804.32.267 0 .535-.106.75-.267 2.142-1.982 4.178-3.91 4.178-8.356a9.44 9.44 0 00-.321-2.465h.32c.644 0 1.287-.107 1.876-.214.16.803.268 1.607.268 2.465 0 5.41-2.625 7.82-4.929 10.017-1.821 1.715-3.535 3.321-3.535 5.893a8.253 8.253 0 01-8.25 8.25 8.253 8.253 0 01-8.25-8.25c0-.59-.482-1.072-1.071-1.072-.59 0-1.072.483-1.072 1.072 0 5.732 4.66 10.393 10.393 10.393 5.733 0 10.393-4.66 10.393-10.393 0-1.66 1.286-2.839 2.84-4.339 2.517-2.357 5.624-5.25 5.624-11.57 0-1.126-.107-2.197-.375-3.215 2.463-1.34 4.23-4.073 4.23-7.233zm-8.25 7.178c-.214 0-.429 0-.643-.053-1.018-.107-1.928-.376-2.785-.857-1.126-.643-2.09-1.554-2.732-2.679a8.19 8.19 0 01-.964-2.785c-.054-.268-.054-.59-.054-.857 0-.536.054-1.072.16-1.553.161-.697.43-1.393.804-1.983 1.232-2.142 3.536-3.642 6.214-3.642a7.179 7.179 0 017.179 7.178c0 2.625-1.447 4.928-3.536 6.16-.59.376-1.286.644-1.982.804-.536.214-1.072.267-1.661.267z"></path>
                    <path d="M66.946 40.482l-2.732-2.732 2.732-2.732a.518.518 0 000-.75.518.518 0 00-.75 0L63.464 37l-2.732-2.732a.518.518 0 00-.75 0 .518.518 0 000 .75l2.678 2.678-2.732 2.732c-.053.054-.107.107-.107.16-.107.215-.053.43.107.59.107.107.268.16.376.16.107 0 .16 0 .214-.053.053 0 .107-.053.16-.107l2.732-2.732 2.679 2.679c.107.107.267.16.375.16a.581.581 0 00.375-.16c.321-.107.321-.428.107-.643z"></path>
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_3_86"
                    width="111"
                    height="111"
                    x="0"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3_86"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_3_86"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default HearingLoss;
